var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import { ConfigContext } from '../config-provider';
const Comment = (_a) => {
    var { actions, author, avatar, children, className, content, prefixCls: customizePrefixCls, datetime } = _a, otherProps = __rest(_a, ["actions", "author", "avatar", "children", "className", "content", "prefixCls", "datetime"]);
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const renderNested = (prefixCls, nestedChildren) => (<div className={classNames(`${prefixCls}-nested`)}>{nestedChildren}</div>);
    const prefixCls = getPrefixCls('comment', customizePrefixCls);
    const avatarDom = avatar ? (<div className={`${prefixCls}-avatar`}>
      {typeof avatar === 'string' ? <img src={avatar} alt="comment-avatar"/> : avatar}
    </div>) : null;
    const actionDom = actions && actions.length ? (<ul className={`${prefixCls}-actions`}>
        {actions.map((action, index) => (<li key={`action-${index}`}>{action}</li> // eslint-disable-line react/no-array-index-key
        ))}
      </ul>) : null;
    const authorContent = (author || datetime) && (<div className={`${prefixCls}-content-author`}>
      {author && <span className={`${prefixCls}-content-author-name`}>{author}</span>}
      {datetime && <span className={`${prefixCls}-content-author-time`}>{datetime}</span>}
    </div>);
    const contentDom = (<div className={`${prefixCls}-content`}>
      {authorContent}
      <div className={`${prefixCls}-content-detail`}>{content}</div>
      {actionDom}
    </div>);
    const cls = classNames(prefixCls, {
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, className);
    return (<div {...otherProps} className={cls}>
      <div className={`${prefixCls}-inner`}>
        {avatarDom}
        {contentDom}
      </div>
      {children ? renderNested(prefixCls, children) : null}
    </div>);
};
export default Comment;
