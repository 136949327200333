import * as React from 'react';
import ConfirmDialog from '../ConfirmDialog';
import defaultLocale from '../../locale/default';
import LocaleReceiver from '../../locale-provider/LocaleReceiver';
import { ConfigContext } from '../../config-provider';
const HookModal = ({ afterClose, config }, ref) => {
    const [visible, setVisible] = React.useState(true);
    const [innerConfig, setInnerConfig] = React.useState(config);
    const { direction, getPrefixCls } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('modal');
    const rootPrefixCls = getPrefixCls();
    const close = (...args) => {
        setVisible(false);
        const triggerCancel = args.some(param => param && param.triggerCancel);
        if (innerConfig.onCancel && triggerCancel) {
            innerConfig.onCancel();
        }
    };
    React.useImperativeHandle(ref, () => ({
        destroy: close,
        update: (newConfig) => {
            setInnerConfig(originConfig => (Object.assign(Object.assign({}, originConfig), newConfig)));
        },
    }));
    return (<LocaleReceiver componentName="Modal" defaultLocale={defaultLocale.Modal}>
      {(modalLocale) => (<ConfirmDialog prefixCls={prefixCls} rootPrefixCls={rootPrefixCls} {...innerConfig} close={close} visible={visible} afterClose={afterClose} okText={innerConfig.okText ||
                (innerConfig.okCancel ? modalLocale.okText : modalLocale.justOkText)} direction={direction} cancelText={innerConfig.cancelText || modalLocale.cancelText}/>)}
    </LocaleReceiver>);
};
export default React.forwardRef(HookModal);
