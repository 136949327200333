var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import useMergedState from 'rc-util/lib/hooks/useMergedState';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import KeyCode from 'rc-util/lib/KeyCode';
import Tooltip from '../tooltip';
import Button from '../button';
import { convertLegacyProps } from '../button/button';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import defaultLocale from '../locale/default';
import { ConfigContext } from '../config-provider';
import { getRenderPropValue } from '../_util/getRenderPropValue';
import { cloneElement } from '../_util/reactNode';
import { getTransitionName } from '../_util/motion';
import ActionButton from '../_util/ActionButton';
const Popconfirm = React.forwardRef((props, ref) => {
    const { getPrefixCls } = React.useContext(ConfigContext);
    const [visible, setVisible] = useMergedState(false, {
        value: props.visible,
        defaultValue: props.defaultVisible,
    });
    // const isDestroyed = useDestroyed();
    const settingVisible = (value, e) => {
        var _a;
        setVisible(value, true);
        (_a = props.onVisibleChange) === null || _a === void 0 ? void 0 : _a.call(props, value, e);
    };
    const close = (e) => {
        settingVisible(false, e);
    };
    const onConfirm = (e) => { var _a; return (_a = props.onConfirm) === null || _a === void 0 ? void 0 : _a.call(this, e); };
    const onCancel = (e) => {
        var _a;
        settingVisible(false, e);
        (_a = props.onCancel) === null || _a === void 0 ? void 0 : _a.call(this, e);
    };
    const onKeyDown = (e) => {
        if (e.keyCode === KeyCode.ESC && visible) {
            settingVisible(false, e);
        }
    };
    const onVisibleChange = (value) => {
        const { disabled } = props;
        if (disabled) {
            return;
        }
        settingVisible(value);
    };
    const renderOverlay = (prefixCls, popconfirmLocale) => {
        const { okButtonProps, cancelButtonProps, title, cancelText, okText, okType, icon, showCancel = true, } = props;
        return (<div className={`${prefixCls}-inner-content`}>
        <div className={`${prefixCls}-message`}>
          {icon}
          <div className={`${prefixCls}-message-title`}>{getRenderPropValue(title)}</div>
        </div>
        <div className={`${prefixCls}-buttons`}>
          {showCancel && (<Button onClick={onCancel} size="small" {...cancelButtonProps}>
              {cancelText || popconfirmLocale.cancelText}
            </Button>)}
          <ActionButton buttonProps={Object.assign(Object.assign({ size: 'small' }, convertLegacyProps(okType)), okButtonProps)} actionFn={onConfirm} close={close} prefixCls={getPrefixCls('btn')} quitOnNullishReturnValue emitEvent>
            {okText || popconfirmLocale.okText}
          </ActionButton>
        </div>
      </div>);
    };
    const { prefixCls: customizePrefixCls, placement, children, overlayClassName } = props, restProps = __rest(props, ["prefixCls", "placement", "children", "overlayClassName"]);
    const prefixCls = getPrefixCls('popover', customizePrefixCls);
    const prefixClsConfirm = getPrefixCls('popconfirm', customizePrefixCls);
    const overlayClassNames = classNames(prefixClsConfirm, overlayClassName);
    const overlay = (<LocaleReceiver componentName="Popconfirm" defaultLocale={defaultLocale.Popconfirm}>
      {(popconfirmLocale) => renderOverlay(prefixCls, popconfirmLocale)}
    </LocaleReceiver>);
    const rootPrefixCls = getPrefixCls();
    return (<Tooltip {...restProps} prefixCls={prefixCls} placement={placement} onVisibleChange={onVisibleChange} visible={visible} overlay={overlay} overlayClassName={overlayClassNames} ref={ref} transitionName={getTransitionName(rootPrefixCls, 'zoom-big', props.transitionName)}>
      {cloneElement(children, {
            onKeyDown: (e) => {
                var _a, _b;
                if (React.isValidElement(children)) {
                    (_b = children === null || children === void 0 ? void 0 : (_a = children.props).onKeyDown) === null || _b === void 0 ? void 0 : _b.call(_a, e);
                }
                onKeyDown(e);
            },
        })}
    </Tooltip>);
});
Popconfirm.defaultProps = {
    placement: 'top',
    trigger: 'click',
    okType: 'primary',
    icon: <ExclamationCircleFilled />,
    disabled: false,
};
export default Popconfirm;
