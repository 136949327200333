var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import EllipsisOutlined from '@ant-design/icons/EllipsisOutlined';
import Button from '../button';
import { ConfigContext } from '../config-provider';
import Dropdown from './dropdown';
const ButtonGroup = Button.Group;
const DropdownButton = props => {
    const { getPopupContainer: getContextPopupContainer, getPrefixCls, direction, } = React.useContext(ConfigContext);
    const { prefixCls: customizePrefixCls, type = 'default', disabled, loading, onClick, htmlType, children, className, overlay, trigger, align, visible, onVisibleChange, placement, getPopupContainer, href, icon = <EllipsisOutlined />, title, buttonsRender = (buttons) => buttons, mouseEnterDelay, mouseLeaveDelay, overlayClassName, overlayStyle, destroyPopupOnHide } = props, restProps = __rest(props, ["prefixCls", "type", "disabled", "loading", "onClick", "htmlType", "children", "className", "overlay", "trigger", "align", "visible", "onVisibleChange", "placement", "getPopupContainer", "href", "icon", "title", "buttonsRender", "mouseEnterDelay", "mouseLeaveDelay", "overlayClassName", "overlayStyle", "destroyPopupOnHide"]);
    const prefixCls = getPrefixCls('dropdown-button', customizePrefixCls);
    const dropdownProps = {
        align,
        overlay,
        disabled,
        trigger: disabled ? [] : trigger,
        onVisibleChange,
        getPopupContainer: getPopupContainer || getContextPopupContainer,
        mouseEnterDelay,
        mouseLeaveDelay,
        overlayClassName,
        overlayStyle,
        destroyPopupOnHide,
    };
    if ('visible' in props) {
        dropdownProps.visible = visible;
    }
    if ('placement' in props) {
        dropdownProps.placement = placement;
    }
    else {
        dropdownProps.placement = direction === 'rtl' ? 'bottomLeft' : 'bottomRight';
    }
    const leftButton = (<Button type={type} disabled={disabled} loading={loading} onClick={onClick} htmlType={htmlType} href={href} title={title}>
      {children}
    </Button>);
    const rightButton = <Button type={type} icon={icon}/>;
    const [leftButtonToRender, rightButtonToRender] = buttonsRender([leftButton, rightButton]);
    return (<ButtonGroup {...restProps} className={classNames(prefixCls, className)}>
      {leftButtonToRender}
      <Dropdown {...dropdownProps}>{rightButtonToRender}</Dropdown>
    </ButtonGroup>);
};
DropdownButton.__ANT_BUTTON = true;
export default DropdownButton;
