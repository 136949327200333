import * as React from 'react';
import Cell from './Cell';
import { DescriptionsContext } from '.';
function renderCells(items, { colon, prefixCls, bordered }, { component, type, showLabel, showContent, labelStyle: rootLabelStyle, contentStyle: rootContentStyle, }) {
    return items.map(({ props: { label, children, prefixCls: itemPrefixCls = prefixCls, className, style, labelStyle, contentStyle, span = 1, }, key, }, index) => {
        if (typeof component === 'string') {
            return (<Cell key={`${type}-${key || index}`} className={className} style={style} labelStyle={Object.assign(Object.assign({}, rootLabelStyle), labelStyle)} contentStyle={Object.assign(Object.assign({}, rootContentStyle), contentStyle)} span={span} colon={colon} component={component} itemPrefixCls={itemPrefixCls} bordered={bordered} label={showLabel ? label : null} content={showContent ? children : null}/>);
        }
        return [
            <Cell key={`label-${key || index}`} className={className} style={Object.assign(Object.assign(Object.assign({}, rootLabelStyle), style), labelStyle)} span={1} colon={colon} component={component[0]} itemPrefixCls={itemPrefixCls} bordered={bordered} label={label}/>,
            <Cell key={`content-${key || index}`} className={className} style={Object.assign(Object.assign(Object.assign({}, rootContentStyle), style), contentStyle)} span={span * 2 - 1} component={component[1]} itemPrefixCls={itemPrefixCls} bordered={bordered} content={children}/>,
        ];
    });
}
const Row = props => {
    const descContext = React.useContext(DescriptionsContext);
    const { prefixCls, vertical, row, index, bordered } = props;
    if (vertical) {
        return (<>
        <tr key={`label-${index}`} className={`${prefixCls}-row`}>
          {renderCells(row, props, Object.assign({ component: 'th', type: 'label', showLabel: true }, descContext))}
        </tr>
        <tr key={`content-${index}`} className={`${prefixCls}-row`}>
          {renderCells(row, props, Object.assign({ component: 'td', type: 'content', showContent: true }, descContext))}
        </tr>
      </>);
    }
    return (<tr key={index} className={`${prefixCls}-row`}>
      {renderCells(row, props, Object.assign({ component: bordered ? ['th', 'td'] : 'td', type: 'item', showLabel: true, showContent: true }, descContext))}
    </tr>);
};
export default Row;
