import * as React from 'react';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import Input from '../input';
export default function Search(props) {
    const { placeholder = '', value, prefixCls, disabled, onChange, handleClear } = props;
    const handleChange = React.useCallback((e) => {
        onChange === null || onChange === void 0 ? void 0 : onChange(e);
        if (e.target.value === '') {
            handleClear === null || handleClear === void 0 ? void 0 : handleClear();
        }
    }, [onChange]);
    return (<Input placeholder={placeholder} className={prefixCls} value={value} onChange={handleChange} disabled={disabled} allowClear prefix={<SearchOutlined />}/>);
}
