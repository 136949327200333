import * as React from 'react';
import classNames from 'classnames';
const Element = (props) => {
    const { prefixCls, className, style, size, shape } = props;
    const sizeCls = classNames({
        [`${prefixCls}-lg`]: size === 'large',
        [`${prefixCls}-sm`]: size === 'small',
    });
    const shapeCls = classNames({
        [`${prefixCls}-circle`]: shape === 'circle',
        [`${prefixCls}-square`]: shape === 'square',
        [`${prefixCls}-round`]: shape === 'round',
    });
    const sizeStyle = typeof size === 'number'
        ? {
            width: size,
            height: size,
            lineHeight: `${size}px`,
        }
        : {};
    return (<span className={classNames(prefixCls, sizeCls, shapeCls, className)} style={Object.assign(Object.assign({}, sizeStyle), style)}/>);
};
export default Element;
