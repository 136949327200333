import * as React from 'react';
import { Circle as RCCircle } from 'rc-progress';
import { presetPrimaryColors } from '@ant-design/colors';
import classNames from 'classnames';
import { validProgress, getSuccessPercent } from './utils';
function getPercentage({ percent, success, successPercent }) {
    const realSuccessPercent = validProgress(getSuccessPercent({ success, successPercent }));
    return [realSuccessPercent, validProgress(validProgress(percent) - realSuccessPercent)];
}
function getStrokeColor({ success = {}, strokeColor, }) {
    const { strokeColor: successColor } = success;
    return [successColor || presetPrimaryColors.green, strokeColor || null];
}
const Circle = props => {
    const { prefixCls, width, strokeWidth, trailColor, strokeLinecap, gapPosition, gapDegree, type, children, success, } = props;
    const circleSize = width || 120;
    const circleStyle = {
        width: circleSize,
        height: circleSize,
        fontSize: circleSize * 0.15 + 6,
    };
    const circleWidth = strokeWidth || 6;
    const gapPos = gapPosition || (type === 'dashboard' && 'bottom') || 'top';
    const getGapDegree = () => {
        // Support gapDeg = 0 when type = 'dashboard'
        if (gapDegree || gapDegree === 0) {
            return gapDegree;
        }
        if (type === 'dashboard') {
            return 75;
        }
        return undefined;
    };
    // using className to style stroke color
    const isGradient = Object.prototype.toString.call(props.strokeColor) === '[object Object]';
    const strokeColor = getStrokeColor({ success, strokeColor: props.strokeColor });
    const wrapperClassName = classNames(`${prefixCls}-inner`, {
        [`${prefixCls}-circle-gradient`]: isGradient,
    });
    return (<div className={wrapperClassName} style={circleStyle}>
      <RCCircle percent={getPercentage(props)} strokeWidth={circleWidth} trailWidth={circleWidth} strokeColor={strokeColor} strokeLinecap={strokeLinecap} trailColor={trailColor} prefixCls={prefixCls} gapDegree={getGapDegree()} gapPosition={gapPos}/>
      {children}
    </div>);
};
export default Circle;
