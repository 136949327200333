import * as React from 'react';
import RcCollapse from 'rc-collapse';
import classNames from 'classnames';
import RightOutlined from '@ant-design/icons/RightOutlined';
import toArray from 'rc-util/lib/Children/toArray';
import omit from 'rc-util/lib/omit';
import CollapsePanel from './CollapsePanel';
import { ConfigContext } from '../config-provider';
import collapseMotion from '../_util/motion';
import { cloneElement } from '../_util/reactNode';
const Collapse = props => {
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const { prefixCls: customizePrefixCls, className = '', bordered = true, ghost } = props;
    const prefixCls = getPrefixCls('collapse', customizePrefixCls);
    const getIconPosition = () => {
        const { expandIconPosition } = props;
        if (expandIconPosition !== undefined) {
            return expandIconPosition;
        }
        return direction === 'rtl' ? 'right' : 'left';
    };
    const renderExpandIcon = (panelProps = {}) => {
        const { expandIcon } = props;
        const icon = (expandIcon ? (expandIcon(panelProps)) : (<RightOutlined rotate={panelProps.isActive ? 90 : undefined}/>));
        return (
        // Create additional div here to make arrow align to center of first line
        <div>
        {cloneElement(icon, () => ({
                className: classNames(icon.props.className, `${prefixCls}-arrow`),
            }))}
      </div>);
    };
    const iconPosition = getIconPosition();
    const collapseClassName = classNames({
        [`${prefixCls}-borderless`]: !bordered,
        [`${prefixCls}-icon-position-${iconPosition}`]: true,
        [`${prefixCls}-rtl`]: direction === 'rtl',
        [`${prefixCls}-ghost`]: !!ghost,
    }, className);
    const openMotion = Object.assign(Object.assign({}, collapseMotion), { motionAppear: false, leavedClassName: `${prefixCls}-content-hidden` });
    const getItems = () => {
        const { children } = props;
        return toArray(children).map((child, index) => {
            var _a;
            if ((_a = child.props) === null || _a === void 0 ? void 0 : _a.disabled) {
                const key = child.key || String(index);
                const { disabled, collapsible } = child.props;
                const childProps = Object.assign(Object.assign({}, omit(child.props, ['disabled'])), { key, collapsible: collapsible !== null && collapsible !== void 0 ? collapsible : (disabled ? 'disabled' : undefined) });
                return cloneElement(child, childProps);
            }
            return child;
        });
    };
    return (<RcCollapse openMotion={openMotion} {...props} expandIcon={renderExpandIcon} prefixCls={prefixCls} className={collapseClassName}>
      {getItems()}
    </RcCollapse>);
};
Collapse.Panel = CollapsePanel;
export default Collapse;
