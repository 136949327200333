import * as React from 'react';
import { SpaceContext } from '.';
export default function Item({ className, direction, index, marginDirection, children, split, wrap, }) {
    const { horizontalSize, verticalSize, latestIndex, supportFlexGap } = React.useContext(SpaceContext);
    let style = {};
    if (!supportFlexGap) {
        if (direction === 'vertical') {
            if (index < latestIndex) {
                style = { marginBottom: horizontalSize / (split ? 2 : 1) };
            }
        }
        else {
            style = Object.assign(Object.assign({}, (index < latestIndex && { [marginDirection]: horizontalSize / (split ? 2 : 1) })), (wrap && { paddingBottom: verticalSize }));
        }
    }
    if (children === null || children === undefined) {
        return null;
    }
    return (<>
      <div className={className} style={style}>
        {children}
      </div>
      {index < latestIndex && split && (<span className={`${className}-split`} style={style}>
          {split}
        </span>)}
    </>);
}
