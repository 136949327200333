import * as React from 'react';
import classNames from 'classnames';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import defaultLocale from '../locale/default';
import Checkbox from '../checkbox';
import TransButton from '../_util/transButton';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
const ListItem = (props) => {
    const { renderedText, renderedEl, item, checked, disabled, prefixCls, onClick, onRemove, showRemove, } = props;
    const className = classNames({
        [`${prefixCls}-content-item`]: true,
        [`${prefixCls}-content-item-disabled`]: disabled || item.disabled,
        [`${prefixCls}-content-item-checked`]: checked,
    });
    let title;
    if (typeof renderedText === 'string' || typeof renderedText === 'number') {
        title = String(renderedText);
    }
    return (<LocaleReceiver componentName="Transfer" defaultLocale={defaultLocale.Transfer}>
      {(transferLocale) => {
            const liProps = { className, title };
            const labelNode = <span className={`${prefixCls}-content-item-text`}>{renderedEl}</span>;
            // Show remove
            if (showRemove) {
                return (<li {...liProps}>
              {labelNode}
              <TransButton disabled={disabled || item.disabled} className={`${prefixCls}-content-item-remove`} aria-label={transferLocale.remove} onClick={() => {
                        onRemove === null || onRemove === void 0 ? void 0 : onRemove(item);
                    }}>
                <DeleteOutlined />
              </TransButton>
            </li>);
            }
            // Default click to select
            liProps.onClick = disabled || item.disabled ? undefined : () => onClick(item);
            return (<li {...liProps}>
            <Checkbox className={`${prefixCls}-checkbox`} checked={checked} disabled={disabled || item.disabled}/>
            {labelNode}
          </li>);
        }}
    </LocaleReceiver>);
};
export default React.memo(ListItem);
