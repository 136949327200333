var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import RcMenu, { ItemGroup } from 'rc-menu';
import classNames from 'classnames';
import omit from 'rc-util/lib/omit';
import EllipsisOutlined from '@ant-design/icons/EllipsisOutlined';
import { forwardRef } from 'react';
import SubMenu, { SubMenuProps } from './SubMenu';
import Item, { MenuItemProps } from './MenuItem';
import { ConfigContext } from '../config-provider';
import devWarning from '../_util/devWarning';
import { SiderContext } from '../layout/Sider';
import collapseMotion from '../_util/motion';
import { cloneElement } from '../_util/reactNode';
import MenuContext, { MenuTheme } from './MenuContext';
import MenuDivider from './MenuDivider';
import useItems from './hooks/useItems';
export { MenuDividerProps } from './MenuDivider';
export { MenuItemGroupProps } from 'rc-menu';
const InternalMenu = forwardRef((props, ref) => {
    const { getPrefixCls, getPopupContainer, direction } = React.useContext(ConfigContext);
    const rootPrefixCls = getPrefixCls();
    const { prefixCls: customizePrefixCls, className, theme = 'light', expandIcon, _internalDisableMenuItemTitleTooltip, inlineCollapsed, siderCollapsed, items, children } = props, restProps = __rest(props, ["prefixCls", "className", "theme", "expandIcon", "_internalDisableMenuItemTitleTooltip", "inlineCollapsed", "siderCollapsed", "items", "children"]);
    const passedProps = omit(restProps, ['collapsedWidth']);
    // ========================= Items ===========================
    const mergedChildren = useItems(items) || children;
    // ======================== Warning ==========================
    if (process.env.NODE_ENV !== 'production')
        devWarning(!('inlineCollapsed' in props && props.mode !== 'inline'), 'Menu', '`inlineCollapsed` should only be used when `mode` is inline.');
    devWarning(!(props.siderCollapsed !== undefined && 'inlineCollapsed' in props), 'Menu', '`inlineCollapsed` not control Menu under Sider. Should set `collapsed` on Sider instead.');
    devWarning(!!items && !children, 'Menu', '`children` will be removed in next major version. Please use `items` instead.');
    // ======================== Collapsed ========================
    // Inline Collapsed
    const mergedInlineCollapsed = React.useMemo(() => {
        if (siderCollapsed !== undefined) {
            return siderCollapsed;
        }
        return inlineCollapsed;
    }, [inlineCollapsed, siderCollapsed]);
    const defaultMotions = {
        horizontal: { motionName: `${rootPrefixCls}-slide-up` },
        inline: collapseMotion,
        other: { motionName: `${rootPrefixCls}-zoom-big` },
    };
    const prefixCls = getPrefixCls('menu', customizePrefixCls);
    const menuClassName = classNames(`${prefixCls}-${theme}`, className);
    // ======================== Context ==========================
    const contextValue = React.useMemo(() => ({
        prefixCls,
        inlineCollapsed: mergedInlineCollapsed || false,
        antdMenuTheme: theme,
        direction,
        firstLevel: true,
        disableMenuItemTitleTooltip: _internalDisableMenuItemTitleTooltip,
    }), [prefixCls, mergedInlineCollapsed, theme, direction, _internalDisableMenuItemTitleTooltip]);
    // ========================= Render ==========================
    return (<MenuContext.Provider value={contextValue}>
      <RcMenu getPopupContainer={getPopupContainer} overflowedIndicator={<EllipsisOutlined />} overflowedIndicatorPopupClassName={`${prefixCls}-${theme}`} {...passedProps} inlineCollapsed={mergedInlineCollapsed} className={menuClassName} prefixCls={prefixCls} direction={direction} defaultMotions={defaultMotions} expandIcon={typeof expandIcon === 'function'
            ? expandIcon
            : cloneElement(expandIcon, {
                className: `${prefixCls}-submenu-expand-icon`,
            })} ref={ref}>
        {mergedChildren}
      </RcMenu>
    </MenuContext.Provider>);
});
// We should keep this as ref-able
class Menu extends React.Component {
    constructor() {
        super(...arguments);
        this.focus = (options) => {
            var _a;
            (_a = this.menu) === null || _a === void 0 ? void 0 : _a.focus(options);
        };
    }
    render() {
        return (<SiderContext.Consumer>
        {(context) => (<InternalMenu ref={node => {
                    this.menu = node;
                }} {...this.props} {...context}/>)}
      </SiderContext.Consumer>);
    }
}
Menu.Divider = MenuDivider;
Menu.Item = Item;
Menu.SubMenu = SubMenu;
Menu.ItemGroup = ItemGroup;
export { MenuTheme, SubMenuProps, MenuItemProps };
export default Menu;
