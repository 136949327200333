import * as React from 'react';
import CSSMotion, { CSSMotionList } from 'rc-motion';
import classNames from 'classnames';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import PaperClipOutlined from '@ant-design/icons/PaperClipOutlined';
import PictureTwoTone from '@ant-design/icons/PictureTwoTone';
import FileTwoTone from '@ant-design/icons/FileTwoTone';
import { cloneElement, isValidElement } from '../../_util/reactNode';
import { previewImage, isImageUrl } from '../utils';
import collapseMotion from '../../_util/motion';
import { ConfigContext } from '../../config-provider';
import Button from '../../button';
import useForceUpdate from '../../_util/hooks/useForceUpdate';
import ListItem from './ListItem';
const listItemMotion = Object.assign({}, collapseMotion);
delete listItemMotion.onAppearEnd;
delete listItemMotion.onEnterEnd;
delete listItemMotion.onLeaveEnd;
const InternalUploadList = ({ listType, previewFile, onPreview, onDownload, onRemove, locale, iconRender, isImageUrl: isImgUrl, prefixCls: customizePrefixCls, items = [], showPreviewIcon, showRemoveIcon, showDownloadIcon, removeIcon, previewIcon, downloadIcon, progress, appendAction, appendActionVisible, itemRender, }, ref) => {
    const forceUpdate = useForceUpdate();
    const [motionAppear, setMotionAppear] = React.useState(false);
    // ============================= Effect =============================
    React.useEffect(() => {
        if (listType !== 'picture' && listType !== 'picture-card') {
            return;
        }
        (items || []).forEach((file) => {
            if (typeof document === 'undefined' ||
                typeof window === 'undefined' ||
                !window.FileReader ||
                !window.File ||
                !(file.originFileObj instanceof File || file.originFileObj instanceof Blob) ||
                file.thumbUrl !== undefined) {
                return;
            }
            file.thumbUrl = '';
            if (previewFile) {
                previewFile(file.originFileObj).then((previewDataUrl) => {
                    // Need append '' to avoid dead loop
                    file.thumbUrl = previewDataUrl || '';
                    forceUpdate();
                });
            }
        });
    }, [listType, items, previewFile]);
    React.useEffect(() => {
        setMotionAppear(true);
    }, []);
    // ============================= Events =============================
    const onInternalPreview = (file, e) => {
        if (!onPreview) {
            return;
        }
        e === null || e === void 0 ? void 0 : e.preventDefault();
        return onPreview(file);
    };
    const onInternalDownload = (file) => {
        if (typeof onDownload === 'function') {
            onDownload(file);
        }
        else if (file.url) {
            window.open(file.url);
        }
    };
    const onInternalClose = (file) => {
        onRemove === null || onRemove === void 0 ? void 0 : onRemove(file);
    };
    const internalIconRender = (file) => {
        if (iconRender) {
            return iconRender(file, listType);
        }
        const isLoading = file.status === 'uploading';
        const fileIcon = isImgUrl && isImgUrl(file) ? <PictureTwoTone /> : <FileTwoTone />;
        let icon = isLoading ? <LoadingOutlined /> : <PaperClipOutlined />;
        if (listType === 'picture') {
            icon = isLoading ? <LoadingOutlined /> : fileIcon;
        }
        else if (listType === 'picture-card') {
            icon = isLoading ? locale.uploading : fileIcon;
        }
        return icon;
    };
    const actionIconRender = (customIcon, callback, prefixCls, title) => {
        const btnProps = {
            type: 'text',
            size: 'small',
            title,
            onClick: (e) => {
                callback();
                if (isValidElement(customIcon) && customIcon.props.onClick) {
                    customIcon.props.onClick(e);
                }
            },
            className: `${prefixCls}-list-item-card-actions-btn`,
        };
        if (isValidElement(customIcon)) {
            const btnIcon = cloneElement(customIcon, Object.assign(Object.assign({}, customIcon.props), { onClick: () => { } }));
            return <Button {...btnProps} icon={btnIcon}/>;
        }
        return (<Button {...btnProps}>
        <span>{customIcon}</span>
      </Button>);
    };
    // ============================== Ref ===============================
    // Test needs
    React.useImperativeHandle(ref, () => ({
        handlePreview: onInternalPreview,
        handleDownload: onInternalDownload,
    }));
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    // ============================= Render =============================
    const prefixCls = getPrefixCls('upload', customizePrefixCls);
    const listClassNames = classNames({
        [`${prefixCls}-list`]: true,
        [`${prefixCls}-list-${listType}`]: true,
        [`${prefixCls}-list-rtl`]: direction === 'rtl',
    });
    // >>> Motion config
    const motionKeyList = [
        ...items.map(file => ({
            key: file.uid,
            file,
        })),
    ];
    const animationDirection = listType === 'picture-card' ? 'animate-inline' : 'animate';
    // const transitionName = list.length === 0 ? '' : `${prefixCls}-${animationDirection}`;
    let motionConfig = {
        motionDeadline: 2000,
        motionName: `${prefixCls}-${animationDirection}`,
        keys: motionKeyList,
        motionAppear,
    };
    if (listType !== 'picture-card') {
        motionConfig = Object.assign(Object.assign({}, listItemMotion), motionConfig);
    }
    return (<div className={listClassNames}>
      <CSSMotionList {...motionConfig} component={false}>
        {({ key, file, className: motionClassName, style: motionStyle }) => (<ListItem key={key} locale={locale} prefixCls={prefixCls} className={motionClassName} style={motionStyle} file={file} items={items} progress={progress} listType={listType} isImgUrl={isImgUrl} showPreviewIcon={showPreviewIcon} showRemoveIcon={showRemoveIcon} showDownloadIcon={showDownloadIcon} removeIcon={removeIcon} previewIcon={previewIcon} downloadIcon={downloadIcon} iconRender={internalIconRender} actionIconRender={actionIconRender} itemRender={itemRender} onPreview={onInternalPreview} onDownload={onInternalDownload} onClose={onInternalClose}/>)}
      </CSSMotionList>

      {/* Append action */}
      {appendAction && (<CSSMotion {...motionConfig} visible={appendActionVisible} forceRender>
          {({ className: motionClassName, style: motionStyle }) => cloneElement(appendAction, oriProps => ({
                className: classNames(oriProps.className, motionClassName),
                style: Object.assign(Object.assign(Object.assign({}, motionStyle), { 
                    // prevent the element has hover css pseudo-class that may cause animation to end prematurely.
                    pointerEvents: motionClassName ? 'none' : undefined }), oriProps.style),
            }))}
        </CSSMotion>)}
    </div>);
};
const UploadList = React.forwardRef(InternalUploadList);
UploadList.displayName = 'UploadList';
UploadList.defaultProps = {
    listType: 'text',
    progress: {
        strokeWidth: 2,
        showInfo: false,
    },
    showRemoveIcon: true,
    showDownloadIcon: false,
    showPreviewIcon: true,
    appendActionVisible: true,
    previewFile: previewImage,
    isImageUrl,
};
export default UploadList;
