var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import RcSteps from 'rc-steps';
import CheckOutlined from '@ant-design/icons/CheckOutlined';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import classNames from 'classnames';
import { ConfigContext } from '../config-provider';
import Progress from '../progress';
import useBreakpoint from '../grid/hooks/useBreakpoint';
const Steps = props => {
    const { percent, size, className, direction, responsive } = props, restProps = __rest(props, ["percent", "size", "className", "direction", "responsive"]);
    const { xs } = useBreakpoint(responsive);
    const { getPrefixCls, direction: rtlDirection } = React.useContext(ConfigContext);
    const getDirection = React.useCallback(() => (responsive && xs ? 'vertical' : direction), [xs, direction]);
    const prefixCls = getPrefixCls('steps', props.prefixCls);
    const iconPrefix = getPrefixCls('', props.iconPrefix);
    const stepsClassName = classNames({
        [`${prefixCls}-rtl`]: rtlDirection === 'rtl',
        [`${prefixCls}-with-progress`]: percent !== undefined,
    }, className);
    const icons = {
        finish: <CheckOutlined className={`${prefixCls}-finish-icon`}/>,
        error: <CloseOutlined className={`${prefixCls}-error-icon`}/>,
    };
    const stepIconRender = ({ node, status, }) => {
        if (status === 'process' && percent !== undefined) {
            // currently it's hard-coded, since we can't easily read the actually width of icon
            const progressWidth = size === 'small' ? 32 : 40;
            const iconWithProgress = (<div className={`${prefixCls}-progress-icon`}>
          <Progress type="circle" percent={percent} width={progressWidth} strokeWidth={4} format={() => null}/>
          {node}
        </div>);
            return iconWithProgress;
        }
        return node;
    };
    return (<RcSteps icons={icons} {...restProps} size={size} direction={getDirection()} stepIcon={stepIconRender} prefixCls={prefixCls} iconPrefix={iconPrefix} className={stepsClassName}/>);
};
Steps.Step = RcSteps.Step;
Steps.defaultProps = {
    current: 0,
    responsive: true,
};
export default Steps;
