var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import { composeRef } from 'rc-util/lib/ref';
import { ConfigContext } from '../config-provider';
import devWarning from '../_util/devWarning';
const Typography = (_a, ref) => {
    var { prefixCls: customizePrefixCls, component = 'article', className, 'aria-label': ariaLabel, setContentRef, children } = _a, restProps = __rest(_a, ["prefixCls", "component", "className", 'aria-label', "setContentRef", "children"]);
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    let mergedRef = ref;
    if (setContentRef) {
        if (process.env.NODE_ENV !== 'production')
            devWarning(false, 'Typography', '`setContentRef` is deprecated. Please use `ref` instead.');
        mergedRef = composeRef(ref, setContentRef);
    }
    const Component = component;
    const prefixCls = getPrefixCls('typography', customizePrefixCls);
    const componentClassName = classNames(prefixCls, {
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, className);
    return (<Component className={componentClassName} aria-label={ariaLabel} ref={mergedRef} {...restProps}>
      {children}
    </Component>);
};
const RefTypography = React.forwardRef(Typography);
RefTypography.displayName = 'Typography';
// es default export should use const instead of let
const ExportTypography = RefTypography;
export default ExportTypography;
