import * as React from 'react';
import { useRef } from 'react';
import { composeRef } from 'rc-util/lib/ref';
import raf from 'rc-util/lib/raf';
import Tooltip from '../tooltip';
const SliderTooltip = React.forwardRef((props, ref) => {
    const { visible } = props;
    const innerRef = useRef(null);
    const rafRef = useRef(null);
    function cancelKeepAlign() {
        raf.cancel(rafRef.current);
        rafRef.current = null;
    }
    function keepAlign() {
        rafRef.current = raf(() => {
            var _a;
            (_a = innerRef.current) === null || _a === void 0 ? void 0 : _a.forcePopupAlign();
            rafRef.current = null;
        });
    }
    React.useEffect(() => {
        if (visible) {
            keepAlign();
        }
        else {
            cancelKeepAlign();
        }
        return cancelKeepAlign;
    }, [visible, props.title]);
    return <Tooltip ref={composeRef(innerRef, ref)} {...props}/>;
});
export default SliderTooltip;
